import React, { Component } from "react";
import { format } from "date-fns";
import { graphql } from "gatsby";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import ArticleTile from "../components/Learn/ArticleTile.div";
import LearnListingPage from "../components/Learn/LearnListingPage.div";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { H3, H4 } from "../components/Type/Display.h";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "Press",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

export default class Press extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const articles = this.props.data.allContentfulPress.edges.map((e) => e.node);

    return (
      <TopLevelLayout>
        <SEO
          title="Press | Upsolve"
          description="Conversations and press involving the Upsolve team and community!"
          image="https://upsolve.org/images/meta-legalaid.png"
          url="https://upsolve.org/press/"
          jsonLD={[
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@context": "http://schema.org",
                  "@type": "ListItem",
                  position: 1,
                  item: {
                    "@id": "https://upsolve.org/press/",
                    name: "Press",
                  },
                },
              ],
            },
          ]}
        />
        <MainHeader trackingPageContext={trackingPageContext} />
        <main>
          <LearnListingPage>
            <div className="learn__header">
              <H3 as="h1">Press</H3>
            </div>
            <div className="learn__listing">
              {articles.map((article) => (
                <ArticleTile key={article.id}>
                  <div>
                    <small>{format(new Date(article.publishedAt), "M/d/yy")}</small>
                    <H4>
                      <a href={article.url}>{article.headline}</a>
                    </H4>
                    <p>
                      <b>{article.publisher}</b>
                      {article.byline != null ? ` -- ` : " "}
                      {article.byline}
                    </p>
                    <a href={article.url}>Read more →</a>
                  </div>
                </ArticleTile>
              ))}
            </div>
          </LearnListingPage>
        </main>
        <MainFooter />
      </TopLevelLayout>
    );
  }
}

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query Press {
    allContentfulPress(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          headline
          byline
          publisher
          publishedAt
          url
        }
      }
    }
  }
`;
